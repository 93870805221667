<div class="create-address">
  <a title="Close (Esc)" class="c-pointer pull-right" (click)="closeDialog()">
    <i class="fa fa-close f-grey"></i>
  </a>
  <h4 class="mb-4 clear">Para usar tus puntos, ingresa tus datos</h4>
  <form [formGroup]="validateCardForm" (ngSubmit)="submit()">
    <div class="form-row">
      <div class="form-group col-12 col-md-12">
        <label>Número de CUIT:</label>
        <input
          formControlName="cuit"
          type="text"
          class="form-control"
          placeholder="18-22456789-1"
          [ngClass]="
            validateCardForm.get('cuit').invalid
              ? 'is-invalid'
              : ''
          " />
        <div
          class="invalid-feedback"
          *ngIf="validateCardForm.controls['cuit'].errors?.required">
          Ingresá número de CUIT.
        </div>
        <div
          class="invalid-feedback"
          *ngIf="validateCardForm.controls['cuit'].errors?.minlength">
          Número de CUIT inválido, debe tener 11 números
        </div>
        <div
          class="invalid-feedback"
          *ngIf="validateCardForm.get('cuit').errors?.maxlength">
          Número de CUIT inválido, debe tener 11 números
        </div>
        <div 
          class="invalid-feedback" 
          *ngIf="validateCardForm.controls['cuit'].errors?.pattern">
          El CUIT solo debe contener números.
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12 col-md-12">
        <label>Últimos 4 números de tarjeta:</label>
        <input
          formControlName="card_number_suffix"
          type="text"
          class="form-control"
          placeholder="Últimos 4 número de tarjeta"
          [ngClass]="
            validateCardForm.get('card_number_suffix').invalid
              ? 'is-invalid'
              : ''
          " />
        <div
          class="invalid-feedback"
          *ngIf="
            validateCardForm.controls['card_number_suffix'].errors?.required
          ">
          Ingresá 4 últimos numeros de tarjeta.
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            validateCardForm.controls['card_number_suffix'].errors?.minlength
          ">
          Debe tener 4 números
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            validateCardForm.controls['card_number_suffix'].errors?.maxlength
          ">
          Debe tener 4 números
        </div>
        <div 
          class="invalid-feedback" 
          *ngIf=" validateCardForm.get('card_number_suffix').errors?.pattern">
          La tarjeta solo debe contener números.
        </div>
      </div>
    </div>

    <div style="display: block;" class="invalid-feedback" *ngIf="validateCardForm.errors">
      {{ validateCardForm.errors.error ? validateCardForm.errors.error : 'Hubo un error' }}
    </div>

    <div class="text-center mt-2">
      <button
        type="submit"
        class="btn btn-primary btn-lg"
        [disabled]="!validateCardForm.valid"
        >Guardar informaci&oacute;n</button
      >
    </div>
  </form>
</div>
