import { map } from 'rxjs/operators';
//Angular
import { Injectable } from '@angular/core';
//Services
import { HttpService } from '../http.service';
import { RelationService } from '../model.service';
//Models
import { Customer } from "../../models/models.model";


@Injectable()

export class ProfileService {
  constructor(private http: HttpService,
              private relationService: RelationService) {}

  show() {
    return this.http.get('my_account/profiles', {}).pipe(
      map(
        (response) => {
          return this.relationService.parse(response, Customer);
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  edit(customer: Customer) {
    return this.http.patch('my_account/profiles', { user: customer });
  }

  updateEmail(email: String) {
    return this.http.patch('my_account/profiles', { user: { email: email }});
  }

  updatePassword(current_password: String, password: String, password_confirmation: String) {
    return this.http.patch(
      'my_account/profiles', 
      { user: 
        {current_password: current_password, 
         password: password, 
         password_confirmation: password_confirmation 
        }
      }
    );
  }

  validateCard(validationCard: any) {
    return this.http.post('verify_card', validationCard)
  }

  getValidateCard() {
    return this.http.get('verify_card')
  }

}
