import { Component, Input, OnInit, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { Variant } from '../../../models/models.model';
import { GtmBuilderService } from '../../../services/gtm_builder.service';
import { PriceService } from "../../../services/price.service";
import { InstallmentService } from "../../../services/views/installment.service";
import { LocalStorage } from "../../../services/local_storage.service";
import { environment } from '../../../environments/environment';
import { ProductsService } from '../../../services/products.services';
import { Router } from '@angular/router';
import { StoreService } from '../../../services/store.service';
import { MktService } from './../../new-core/core/services/mkt.service';
import { DomSanitizer } from "@angular/platform-browser";
import { STORE_NAME } from "../../core/constants/store.constants";

@Component({
  selector: 'app-variant-card',
  templateUrl: './variant-card.component.html',
  styleUrls: ['./variant-card.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [ProductsService]
})

export class VariantCardComponent implements OnInit {
  @Input('variant') variant: Variant;
  @Input('slug') slug: string;
  @Input('position') position: number;
  @Input('catalog') catalog: boolean = false;

  env = environment
  store = environment.name
  use_bines: boolean
  variantsPoints = []
  onlyVariant: boolean
  minPoints: number
  maxPoints: number
  salePrice: number
  macroPoints: number
  regularPrice: number
  manufacturerTitle: string | null;
  shopTitle: String
  hasInstallments: boolean = true

  constructor(private gtmBuilderService: GtmBuilderService,
    private localStorage: LocalStorage,
    private installmentsService: InstallmentService,
    private storeService: StoreService,
    private productsService: ProductsService,
    private router: Router,
    private _mktService: MktService,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    this.macroPoints = Math.ceil(this.variant.product.points * 0.2);
    this.salePrice = Math.ceil(this.variant.product.sale_price * 0.8);
    this.regularPrice = Math.ceil(this.variant.product.regular_price * 0.8);
    this.manufacturerTitle = this.variant.product.manufacturer ? this.variant.product.manufacturer.name : null;
    this.shopTitle = this.variant.product.shop_public_name ? this.variant.product.shop_public_name : this.variant.product.shop_title;
    if (['voucher', 'cashback', 'points', 'cashback_voucher'].includes(this.variant.product.transaction_type)) {
      this.minPoints = this.variant.product.min_points_price_for_variants ? this.variant.product.min_points_price_for_variants : 0;
      this.onlyVariant = this.variant.product.available_properties.length < 1 || this.variant.product.available_properties[0] == 'noproperty' ? true : false;
    }
    this.storeService.getStore().subscribe(res => this.use_bines = res.use_bines);
  }

  // CANNOT BE DELETED YET BECAUSE OF FLOATPARSEINT
  floatParse(attr) {
    return PriceService.humanize(attr);
  }

  floatParseToInt(amount) {
    let noDotValue = (this.floatParse(amount) as string).replace(".", "")
    let newInt = (parseFloat(noDotValue) + 1).toString();
    let withDotValue = newInt.replace(/(\d+)(\d{3})/, '$1' + '.' + '$2')
    return withDotValue
  }

  trackProductClick() {
    if (this.slug) {
      this.gtmBuilderService.builderProductClick(this.variant, this.slug, this.position);
    }
  }

  //CANNOT REMOVE BECAUSE OF buildInstallmentsAmount()
  buildInstallments() {
    if (this.variant.product.max_installments_info.installments > 1) {
      this.hasInstallments = true
      return InstallmentService.build(this.variant.product.max_installments_info.installments);
    } else {
      this.hasInstallments = false
    }
  }

  buildInstallmentsAmount() {
    return this.variant.product.max_installments_info.amount;
  }

  getProductMaxPromotion() {
    const maxInstallment = this.installmentsService.getProductMaxInstallment(this.variant)
    const promotion = {
      intereses: (maxInstallment.coef === 0) ? false : true,
      installments: maxInstallment.installments,
      installments_amount: maxInstallment.installments_amount,
    }
    return promotion
  }

  protected readonly STORE_NAME = STORE_NAME;
}
