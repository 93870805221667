import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../environments/environment";
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
//Models
import { Menu } from '../../../../models/models.model';
//Components
import { TijeComponent } from "../../../dialogs/tije/tije.component";
import { MacroMillasComponent } from "../../../dialogs/macro-millas/macro-millas.component";
//Services
import { UrlService } from "../../../../services/url.service";
import { Router } from "@angular/router";
import { GtmBuilderService } from "../../../../services/gtm_builder.service";

@Component({
  selector: 'app-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonLinkComponent implements OnInit {
  @Input('menu') menu: Menu;
  env = environment
  constructor(private dialog: MatDialog,
              private urlService: UrlService,
              private router: Router,
              private _gtmBuilderService: GtmBuilderService
            ) {}

  ngOnInit() {
  }

  navigate(e) {
    if(this.env.name === 'Macro'){
      const optionSelected = this.menu.getName()
      this._gtmBuilderService.buildMpMenuPrincipal(optionSelected)
    }
    if (this.menu.isTravel()) {
      if (environment.name === "Avenida") this.dialog.open(TijeComponent).updateSize('790px', '510px');
    }
    // else if (environment.name === "Macro" && this.menu.url === '/aerolineas-plus') {
    //   this.dialog.open(MacroMillasComponent, {
    //     width: '500px',
    //     height: '300px'
    //   })
    // }
    else {
      this.urlService.navigate(this.menu.url);
    }
  }

  getDataTrackingId(arg: string): string {
    switch (arg) {
      case 'Tecnología':
        return 'Boton-tecnologia';

      case 'Electrodomésticos':
        return 'Boton-Electrodomesticos';

      case 'Hogar':
        return 'Boton-Hogar';

      case 'Deportes y Movilidad':
        return 'Boton-Deportes-movilidad';

      case 'Salud y Belleza':
        return 'Boton-Salud-Belleza';

      case 'Moda':
        return 'Boton-Moda';

      case 'Otras categorías':
        return 'Boton-Otras-categorias';

      case 'Liquidación':
        return 'boton-liquidacion';

      case 'Generación D':
        return 'boton-generacion-d';

      case 'Vendé con nosotros':
        return 'boton-vende-con-nosotros';

      default:
        return null;

    }
  }

}
