import { Banner } from "../../../../../interfaces/banner.interface";
import { ComponentLanding } from "../../../../../models/models.model";
import {
  Component,
  Input,
  OnInit,
  HostListener,
  OnDestroy
} from "@angular/core";
import { UrlService } from "../../../../../services/url.service";

// import Swiper core and required modules
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  Swiper
} from "swiper/core";
import { MktService } from "../../../../new-core/core/services/mkt.service";
import { GtmBuilderService } from "../../../../../services/gtm_builder.service";
import { environment } from "../../../../../environments/environment";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

@Component({
  selector: "app-hero",
  templateUrl: "./hero.component.html",
  styleUrls: ["./hero.component.sass"]
})

export class HeroComponent implements OnInit, OnDestroy {
  private readonly  env = environment
  component: ComponentLanding;
  mobile: boolean = false;
  banners: Banner[] = [];

  static acceptsComponent(cmp: ComponentLanding): boolean {
    return cmp.type === "hero";
  }

  constructor(
    private _urlService: UrlService,
    private _mktService: MktService,
    private _gtmBuilderService: GtmBuilderService
  ) {
  }

  ngOnDestroy(): void {
    this.banners = [];
  }

  ngOnInit(): void {
    this.mobile = window.innerWidth < 425;
    this.getImages();
    // console.log(this.banners);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.mobile = window.innerWidth < 425;
  }

  getImages() {
    this.component.data.banners.forEach((element) => {
      if (element?.image || element?.mobile_image) this.banners.push(element);
    });
  }

  navigate(item) {
    if (this.env.name === 'Macro') this._gtmBuilderService.builderMpClickBanner(item)
    this._mktService.collect("CLICK-IN-BANNER", item);
    this._urlService.navigate(this._urlService.linkSelector(item));
  }

}
