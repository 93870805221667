import {Injectable} from "@angular/core";
import { environment } from "../../environments/environment";
import { Bank, CardBrand, Product } from "../../models/models.model";
import { CheckoutCart } from "../../models/models.model";
import { HttpService } from '../http.service';
import { Installment } from "../../models/models.model";
import { Variant } from "../../models/models.model";
import { PriceService } from '../../services/price.service';

@Injectable()
export class InstallmentService {

  apiUrl = "checkout/installments"

  static build(installment) {
    if (installment === 0 || installment > environment.installments) {
      return environment.installments;
    } else {
      return installment;
    }
  }


  static buildText(max_installments_info: any): string {
    return max_installments_info.coef === 0
            ? (parseInt(max_installments_info.installments) === 1
            ? 'cuota sin interés de' : 'cuotas sin interés de')
            : 'cuotas fijas de';
  }

  buildInstallmentsText(promotions: Bank[]): string {
    return this.compararInstallments(promotions)
  }

  static installmentAmout(amount: number,coef: number, installment: number){
    if (coef==0){
      if (installment==1) return amount;
      return amount/installment;
    } else {
      return amount * coef;
    }
  }

  static installmentTotal(amount:number, coef: number, installment: number){
    return this.installmentAmout(amount, coef, installment) * installment;
  }

  constructor(
    private httpService: HttpService,
  ) { }

  productInstallments(product: Product){
    if (product) return this.runInstallments(product);
  }

  maxInstallment(checkoutCart: CheckoutCart){
    let installments: number[] = [];
    let smallestInstallment: number;
    let difference = false;
    checkoutCart.checkout_items.forEach((item,index) => {
      this.runInstallments(item.product).forEach((element,index2) => {
        if(index2 == 0) installments[index] = element.installments;
        if(installments[index] < element.installments) installments[index] = element.installments;
      });
    });
    installments.forEach((el,index) => {
      if(index == 0) smallestInstallment = el;
      if(el != smallestInstallment) difference = true;
    });
    return difference;
  }

  runInstallments(product: Product){
    return product.promotions[0].brands[0].installments;
  }

  getProductMaxInstallment(variant) {
    const installments = variant.promotions[0].brands[0].installments;
    return installments[installments.length - 1]
  }

  getInstallments(params: any) {
    return this.httpService.post(this.apiUrl, params);
  }

  private _getMaxInstallments (installments: Installment[]) {
    if (!installments) return { maxInstallments: -1, maxInstallmentsAmount: -1, maxInstallmentsCoef: -1, maxInstallmentsAmountCoef: -1 }
    // 1. Filtro si hay cuotas sin interés
    const sinInteres = installments.filter(installment=> installment.coef === 0 && installment.installments > 1)
    // 2. Obtengo la mayor cantidad de cuotas sin interés
    const maxInstallmentsCoef = sinInteres.length ?  Math.max(...sinInteres?.map(installment =>  installment.installments)) : -1
    // 3. Obtengo la mayor cantidad de cuotas con interes
    const maxInstallments = Math.max(...installments.map(installment => installment.installments))
    // 4. Obtengo el monto de la mejor cuota sin interés
    const maxInstallmentsAmountCoef = installments.find(installment => installment.installments === maxInstallmentsCoef)?.installments_amount ?? -1
    // 5. Obtengo el monto de la mejor cuota
    const maxInstallmentsAmount = installments.find(installment => installment.installments === maxInstallments)?.installments_amount
    // console.log( { maxInstallments, maxInstallmentsAmount, maxInstallmentsCoef, maxInstallmentsAmountCoef });
    return { maxInstallments, maxInstallmentsAmount, maxInstallmentsCoef, maxInstallmentsAmountCoef }
  }

  compararInstallments(promotions: Bank[]) {
    const banco = promotions.find(bank => bank.name === "BANCO DE LA NACION ARGENTINA")
    if (!banco) return ''

    // Buscar si existen promociones con tarjeta y modo
    const visa = banco.brands.find(brand => brand.brand === "visa")
    const modo = banco.brands.find(brand => brand.brand === "modo")

    const {
      maxInstallments: maxVisaInstallment,
      maxInstallmentsAmount: maxVisaInstallmentsAmount,
      maxInstallmentsCoef: maxVisaInstallmentCoef,
      maxInstallmentsAmountCoef: maxVisaInstallmentsAmountCoef
    } = this._getMaxInstallments(visa?.installments)
    const {
      maxInstallments: maxModoInstallment,
      maxInstallmentsAmount: maxModoInstallmentsAmount,
      maxInstallmentsCoef: maxModoInstallmentCoef,
      maxInstallmentsAmountCoef: maxModoInstallmentsAmountCoef
    } = this._getMaxInstallments(modo?.installments)

    if (maxModoInstallmentCoef > maxVisaInstallmentCoef && maxModoInstallmentsAmountCoef > 0) {
      return `${maxModoInstallmentCoef} cuotas sin interés de $${PriceService.humanize(maxModoInstallmentsAmountCoef)} con MODO BNA+`
    } else if (maxVisaInstallmentCoef > maxModoInstallmentCoef && maxVisaInstallmentsAmountCoef > 0) {
      return `${maxVisaInstallmentCoef} cuotas sin interés de $${PriceService.humanize(maxVisaInstallmentsAmountCoef)} con tarjeta de crédito`
    }else if(maxModoInstallmentCoef === maxVisaInstallmentCoef && maxModoInstallmentsAmountCoef > 0 && maxVisaInstallmentsAmountCoef > 0){
      return `${maxVisaInstallmentCoef} cuotas sin interés de $${PriceService.humanize(maxVisaInstallmentsAmountCoef)}`
    } else if (maxModoInstallment > maxVisaInstallment) {
      return `${maxModoInstallment} cuotas con interés de $${PriceService.humanize(maxModoInstallmentsAmount)} con MODO BNA+`
    } else if (maxVisaInstallment > maxModoInstallment) {
      if (maxVisaInstallment === 1) {
        return `1 pago de $${PriceService.humanize(maxVisaInstallmentsAmount)}`  
      }
      return `${maxVisaInstallment} cuotas con interés de $${PriceService.humanize(maxVisaInstallmentsAmount)} con tarjeta de crédito`
    } else {
      if (maxVisaInstallment === 1) {
        return `1 pago de $${PriceService.humanize(maxVisaInstallmentsAmount)}`  
      }
      return `${maxVisaInstallment} cuotas con interés de $${PriceService.humanize(maxVisaInstallmentsAmount)}`
    }
  }

}
