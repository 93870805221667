// ANGULAR
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// SERVICES
import { AuthenticationService } from '../../../services/authentication.service';
import { MacroService } from '../../../services/macro.service';
// STORE KEYS
import { environment } from '../../../environments/environment';
import { QualtricsService } from '../../../services/third-party/qualtrics.service';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.sass']
})
export class SessionExpiredComponent implements OnInit {

  tienda: string
  site_title: string

  constructor(
    private macroService: MacroService,
    private router: Router,
    private authService: AuthenticationService,
    private _qualtricsService: QualtricsService
  ) { }

  ngOnInit() {
    this.tienda = environment.name;
    this.site_title = environment.site_title;
    if (environment.name === 'Macro') {
      window.sessionStorage.setItem("user.cierreSesion", "1");
      window.sessionStorage.setItem("user.tipoCierre", "1");
      this.macroService.macroLogout();
      this._qualtricsService.runInterceptor()
    } else {
      (!this.authService.isLoggedIn()) ? this.router.navigate(['/']) : this.authService.logout(); ;
    }
  }

  redirectToLogin() {
    if ( this.tienda === "Macro" ) {
      this.macroService.redirectToLogin();
    } else {
      this.router.navigate(['/login'])
    }
  }

  backToHome() {
    this.router.navigate(['/']);
  }

}
