import { Pipe, PipeTransform } from '@angular/core';
import { InstallmentService } from '../../services/views/installment.service';
import { Product, Variant } from "../../models/models.model";
import { STORE_NAME } from "../core/constants/store.constants";

@Pipe({
  name: 'buildInstallmentsTextProduct',
  pure: true,
})
export class BuildInstallmentsTextProductPipe implements PipeTransform {
  constructor(
    private _installmentService: InstallmentService
  ) {
  }

  transform(product: Product | Variant, store_name: STORE_NAME = null): string {
    if (STORE_NAME.BANCO_NACION === store_name) {
      return this._installmentService.buildInstallmentsText(product?.promotions);
    }
    // @ts-ignore
    return InstallmentService.buildText(product?.max_installments_info);
  }

}
