import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Customer } from 'models/models.model';
import { ProfileService } from 'services/account/profile.service';
import { AuthenticationService } from 'services/authentication.service';
import { CustomerService } from 'services/customer.service';

interface IVerifyCardData {
  points_enabled: boolean;
  doc_number: number;
  cuit: number;
  card_number_suffix: number | null;
  card_type: any;
  current_customer_points: number;
}

@Component({
  selector: 'app-dialog-validate-card',
  templateUrl: './dialog-validate-card.component.html',
  styleUrls: ['./dialog-validate-card.component.sass']
})
export class DialogValidateCardComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DialogValidateCardComponent>,
    private profileService: ProfileService,
    private _customerService: CustomerService,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
  ) {}

  validationCard: {
    cuit: number;
    card_number_suffix: number;
  };
  validateCardForm: FormGroup;
  verifyCardData: IVerifyCardData = null;

  ngOnInit() {
    this.profileService.getValidateCard().subscribe(
      (data: IVerifyCardData) => {
        this.validateCardForm.patchValue({
          cuit: data?.cuit ? data?.cuit : null
        });
      },
      (err) => console.error(err)
    );
    this.buildForm();
  }

  buildForm() {
    this.validateCardForm = this.fb.group({
      cuit: [this.verifyCardData?.cuit,
          Validators.compose([
            Validators.required,
            Validators.pattern(/^[0-9]+$/),
            Validators.minLength(11),
            Validators.maxLength(11), 
        ])
      ],
      card_number_suffix: [null,
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9]+$/),
          Validators.minLength(4),
          Validators.maxLength(4), 
      ])
      ]
    });
  }
  submit() {
    if (this.validateCardForm.valid) {
      this.validationCard = this.validateCardForm.value;
      this.create();
    }
  }

  create() {
    this.profileService.validateCard(this.validationCard).subscribe(
      (res) => {
        this.dialogRef.close();
        this._customerService.init().subscribe(
          (response: Customer) => {
            this._customerService.setCustomer(response);
            this.authenticationService.broadcastLogin();
          },
          (error) => {
            console.log(error);
          }
        );
      },
      (err) => {
        console.error(err);
        this.validateCardForm.setErrors({ error: err.error.error });
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
