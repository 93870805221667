import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BuildInstallmentsTextProductPipe
} from "./build-installments-text-product.pipe";



@NgModule({
  declarations: [
    BuildInstallmentsTextProductPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BuildInstallmentsTextProductPipe
  ]
})
export class BuildInstallmentsTextProductModule { }
