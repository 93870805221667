import {Component, Input, OnInit} from '@angular/core';
import {UrlService} from "../../../../../services/url.service";
import { MktService } from './../../../../new-core/core/services/mkt.service';
import { GtmBuilderService } from "../../../../../services/gtm_builder.service";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html'
})
export class ImageComponent implements OnInit {
  private readonly env = environment
  @Input('item') item: any;

  constructor(
    private urlService: UrlService,
    private _mktService: MktService,
    private _gtmBuilderService: GtmBuilderService
    ) { }

  ngOnInit() {
    // console.log(this.item);
  }

  hasTitle(): boolean {
    return (this.item.title !== undefined)  && (this.item.title.length > 0);
  }

  navigate(link) {
    if (this.env.name === 'Macro') this._gtmBuilderService.builderSelectPromotion(this.item)
    this._mktService.collect('CLICK-IN-OFFER', link)
    this.urlService.navigate(this.urlService.linkSelector(this.item));
  }
}
