export const STORE_NAME = {
  AVENIDA: "Avenida",
  BANCO_CIUDAD: "BancoCiudad",
  ESPECIALES_BANCO_CIUDAD: "EspecialesBancoCiudad",
  BANCO_COLUMBIA: "BancoColumbia",
  BANCO_NACION: "BancoNacion",
  ESPECIALES_BNA: "EspecialesBNA",
  MI_MOTO: "MiMoto",
  MACRO: "Macro",
  PASEO_LIBERTAD: "PaseoLibertad",
  SPORT_CLUB: "Soport Club"
} as const;

export type STORE_NAME = typeof STORE_NAME[keyof typeof STORE_NAME]
