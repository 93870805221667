import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'ngx-moment';
import { NgxMaskModule } from 'ngx-mask'

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule  } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';

import { AppComponent } from './app.component';
import { AuthenticationService } from '../services/authentication.service';
import { CustomerService } from '../services/customer.service';
import { UserIdleService } from '../services/user-inactivity.service';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { MenusService } from '../services/menus.service';
import { SitemapsService } from '../services/sitemaps.service';
import { LoaderComponent } from './layout/loader/loader.component';
import { HttpService } from '../services/http.service';
import { LoaderService } from '../services/loader.service';
import { ButtonLinkComponent } from './layout/header/button-link/button-link.component';
import { ButtonLinkMobileComponent } from './layout/header/button-link-mobile/button-link-mobile.component';
import { ProductService } from '../services/product.service';
import { StoreService } from '../services/store.service';
import { RelationService } from '../services/model.service';
import { LocalStorageModule } from 'angular-2-local-storage';
import { LocalStorage } from '../services/local_storage.service';
import { CategoriesService } from '../services/model.service';
import { BinesService } from '../services/bines.service';
import { GtmService } from '../services/gtm.service';
import { GtmBuilderService } from '../services/gtm_builder.service';
import { GtmFbService } from '../services/gtm_fb.service';
import { CartComponent } from './dialogs/cart/cart.component';
import { ProviderFormComponent } from './dialogs/provider-form/provider-form.component';
import { PhoneDialogComponent } from './dialogs/phone-dialog/phone-dialog.component';
import { SearchComponent } from './layout/header/search/search.component';
import { HiddenSearchComponent } from './layout/header/hidden-search/hidden-search.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { OrdersService } from "../services/orders.service";
import { NewsletterService } from "../services/newsletter.service";
import { PaymentMethodsComponent } from './dialogs/payment/payment_methods.component';
import { LocalStoragePolyfill } from "../services/local_storage_polyfill";
import { TrackingComponent } from './tracking/tracking.component';
import { TurismoComponent } from './pages/turismo/turismo.component';
import { DefaultComponent } from "./pages/landings/components/default/default.component";
import { Cols4VariantsV1Component } from "./pages/landings/components/cols4-variants-v1/cols4-variants-v1.component";
import { Cols4BrandsV1Component } from "./pages/landings/components/cols4-brands-v1/cols4-brands-v1.component";
import { Cols1Pics2V1Component } from "./pages/landings/components/cols1-pics2-v1/cols1-pics2-v1.component";
import { Cols1Pics1V1Component } from "./pages/landings/components/cols1-pics1-v1/cols1-pics1-v1.component";
import { Cols2BrandV2Component } from "./pages/landings/components/cols2-brand-v2/cols2-brand-v2.component";
import { Cols4LinksV1Component } from "./pages/landings/components/cols4-links-v1/cols4-links-v1.component";
import { Cols2Pic3V1Component } from "./pages/landings/components/cols2-pic3-v1/cols2-pic3-v1.component";
import { SliderComponent } from "./pages/landings/components/slider/slider.component";
import { MiniLogoComponent } from "./pages/landings/components/mini-logo/mini-logo.component";
import { SitemapComponent } from "./sitemap/sitemap.component";
import { ImageComponent } from "./pages/landings/components/image/image.component";
import { SubscriptionComponent } from "./pages/landings/components/subscription/subscription.component";
import { HeroComponent } from "./pages/landings/components/hero/hero.component";
import { EditorComponent } from './pages/landings/components/editor/editor.component';
import { HeroBannerComponent } from "./pages/landings/components/hero-banner/hero-banner.component";
import { Cols2GenericV1Component} from "./pages/landings/components/cols2-generic-v1/cols2-generic-v1.component";
import { Cols4PostsV1Component} from "./pages/landings/components/cols4-posts-v1/cols4-posts-v1.component";
import { Cols5GenericV1Component} from "./pages/landings/components/cols5-generic-v1/cols5-generic-v1.component";
import { BaseComponent} from "./pages/landings/base.component";
import { TermsService} from "../services/terms.service";
import { TermsComponent } from './pages/terms/terms.component';
import { TermsDialogComponent } from './dialogs/terms-dialog/terms-dialog.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { InsuranceService } from "../services/insurance.service";
import { DecidirInsuranceComponent } from './dialogs/decidir-insurance/decidir-insurance.component';
import { DecidirInsuranceReminderComponent } from './dialogs/decidir-insurance-reminder/decidir-insurance-reminder.component';
import { PriceService } from "../services/price.service";
import { UrlService} from "../services/url.service";
import { InstallmentService} from "../services/views/installment.service";
import { BrandService} from "../services/brand.service";
import { LegacyComponent } from './legacy/legacy.component';
import { CustomerComponent } from "./customer/customer.component";
import { CapitalizePipeModule } from "../pipes/capitalize.pipe.module";
import { appRouting } from "./app.routing";
import { GlobalModule } from "../modules/global.module";
import { BreadcrumbModule } from "../modules/breadcrumb.module";
import { RouterModule } from "@angular/router";
import { VariantCardModule } from "./layout/variant-card/variant.card.module";
import { BigloaderModule } from "../modules/bigloader.module";
import { SkeletonModule } from "../modules/skeleton.module";
import { NewsletterComponent } from './dialogs/newsletter/newsletter.component';
import { TijeComponent } from './dialogs/tije/tije.component';
import { DialogEditProfileComponent } from './account/profile/dialogeditprofile.component'
import { DialogCreateAddressComponent } from './account/addresses/dialogcreateaddress.component'
import { DialogDeleteAddressComponent } from './account/addresses/dialogdeleteaddress.component'
import { CheckoutDialogComponent } from "./dialogs/checkout/checkout.dialog.component";
import { LoginDialogComponent } from "./dialogs/login/login.dialog.component";
import { SuggestionsService } from '../services/suggestions.service';
import { SuggestionComponent } from './layout/header/search/suggestions/suggestion.component';
import { LoginComponent } from "./customer/login/login.component";
import { LogoutComponent } from "./customer/logout/logout.component";
import { RegisterComponent } from "./customer/register/register.component";
import { AccountNavComponent } from './layout/header/account-nav/account-nav.component';
import { CouponsService } from '../services/account/coupons.service';
import { AddressesService } from '../services/account/addresses.service';
import { ProfileService } from '../services/account/profile.service';
import { AccountOrdersService } from '../services/account/account_orders.service';
import { AuthGuardService } from './auth-guard.service';
import { WindowService } from '../services/window.service';
import { PromotionDialogComponent } from './dialogs/promotions/promotion.dialog.component';
import { ExternalComponent } from './customer/external/external.component';
import { MacroService } from '../services/macro.service';
import { BnaService } from '../services/bna.service';
import { PointsService } from '../services/points.service';
import { PaginationService } from '../services/pagination.service';
import { DespegarService } from '../services/despegar.service';
import { DespegarComponent } from './pages/despegar/despegar.component';
import { OfferComponent } from './dialogs/offer/offer.component';
import { TechnisysService } from '../services/technisys.service';
import { TermsOfServicesComponent } from './dialogs/terms-of-services/terms-of-services.component';
import { AerolineasArgentinasComponent } from './pages/aerolineas-argentinas/aerolineas-argentinas.component';
import { InsuranceComponent } from './pages/insurance/insurance.component';
import { AerolineasArgentinasService } from '../services/aerolineas-argentinas.service';
import { ProgramaVolarService } from '../services/programa-volar.service';
import { RecargaCelularesService } from '../services/recarga-celulares.service';
import { ReservableService } from '../services/reservable.service';
import { SubeComponent } from './pages/sube/sube.component';
import { SubeService } from '../services/sube.service';
import { NewCardComponent } from './dialogs/sube/new-card/new-card.component';
import { SelectCardComponent } from './dialogs/sube/select-card/select-card.component';
import { EditCardComponent } from './dialogs/sube/edit-card/edit-card.component';
import { CelularesComponent } from './pages/celulares/celulares.component';
import { UpdateProfileDialogComponent } from './dialogs/updateprofile/updateprofile.dialog.component';
import { SessionExpiredComponent } from './pages/session-expired/session-expired.component';
import { CatalogService } from "../services/catalog.service";
import { GeolocationService } from "../services/geolocation.service";
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { ReservationConfirmComponent } from './products/reservable/reservation-confirm/reservation-confirm.component';
import { GeolocationDialogComponent } from './dialogs/geolocation/geolocation-dialog.component';
import { AgentComponent } from './pages/agent/agent.component';
import { SwiperModule } from 'swiper/angular';

import { QuicklinkModule } from 'ngx-quicklink';
import { CoreModule } from './core/core.module';

import { ReactiveFormsModule } from '@angular/forms';
import { AerolineasCanActivateGuardService } from './guards/aerolineas-argentina.can-activate.guard';
import { TrackingCanActivateGuardService } from './guards/tracking.can-activate.guard';

import { ReCaptchaModule } from 'angular-recaptcha3';

import { environment } from '../environments/environment';
import { FloatParseModule } from './pipes/float-parse.module';

import { MacroMillasComponent } from './dialogs/macro-millas/macro-millas.component'

import { ProgramaVolarComponent } from './pages/programa-volar/programa-volar.component'
import { DialogValidateCardComponent } from './dialogs/dialog-validate-card/dialog-validate-card.component';

@NgModule({
declarations: [
AppComponent,
HeaderComponent,
FooterComponent,
HomeComponent,
LoaderComponent,
ButtonLinkComponent,
ButtonLinkMobileComponent,
DefaultComponent,
Cols4VariantsV1Component,
Cols4BrandsV1Component,
HeroComponent,
HeroBannerComponent,
Cols2GenericV1Component,
Cols1Pics1V1Component,
CartComponent,
ProviderFormComponent,
PhoneDialogComponent,
NewsletterComponent,
TijeComponent,
DialogEditProfileComponent,
DialogCreateAddressComponent,
DialogDeleteAddressComponent,
CheckoutDialogComponent,
LoginDialogComponent,
UpdateProfileDialogComponent,
SearchComponent,
HiddenSearchComponent,
Cols1Pics2V1Component,
Cols2BrandV2Component,
Cols4LinksV1Component,
Cols4PostsV1Component,
Cols5GenericV1Component,
Cols2Pic3V1Component,
SliderComponent,
MiniLogoComponent,
SitemapComponent,
EditorComponent,
ErrorPageComponent,
ImageComponent,
PaymentMethodsComponent,
TrackingComponent,
TurismoComponent,
BaseComponent,
TermsComponent,
TermsDialogComponent,
DecidirInsuranceComponent,
DecidirInsuranceReminderComponent,
LegacyComponent,
SubscriptionComponent,
SuggestionComponent,
LoginComponent,
LogoutComponent,
RegisterComponent,
CustomerComponent,
AccountNavComponent,
PromotionDialogComponent,
ExternalComponent,
DespegarComponent,
OfferComponent,
TermsOfServicesComponent,
AerolineasArgentinasComponent,
InsuranceComponent,
SubeComponent,
NewCardComponent,
SelectCardComponent,
EditCardComponent,
CelularesComponent,
SessionExpiredComponent,
MaintenanceComponent,
ReservationConfirmComponent,
GeolocationDialogComponent,
FaqsComponent,
AgentComponent,
MacroMillasComponent,
ProgramaVolarComponent,
DialogValidateCardComponent,
],
imports: [
  CoreModule,
// Pipes
  CapitalizePipeModule,
  SwiperModule,

  ReactiveFormsModule,
  ReCaptchaModule.forRoot({
    invisible: {
        sitekey: environment.recaptcha_key,
    },
    normal: {
        sitekey: environment.recaptcha_key,
    },
    language: 'es'
}),

// Angular stuffs
BrowserAnimationsModule,
HttpClientModule,
BrowserModule.withServerTransition({ appId: 'avenidafrontend' }),
BrowserTransferStateModule,
RouterModule,

// Custom module
GlobalModule,
BreadcrumbModule,
VariantCardModule,
BigloaderModule,
SkeletonModule,

// Angular Material
MatProgressBarModule,
MatInputModule,
MatSelectModule,
MatGridListModule,
MatDialogModule,
MatButtonModule,
MatCardModule,
MatToolbarModule,
MatMenuModule,
MatDatepickerModule,
MatNativeDateModule,
MatCheckboxModule,
// NgxGalleryModule,
MatStepperModule,
MatSliderModule,
MatIconModule,
MatSlideToggleModule,
MatRadioModule,
// Router
appRouting,
// Local Storage
LocalStorageModule.forRoot({
  prefix: 'my-app',
  storageType: 'localStorage'
}),
MomentModule,
NgxMaskModule.forRoot(),
QuicklinkModule,
FloatParseModule,
],
providers: [
AuthenticationService,
CustomerService,
UserIdleService,
BinesService,
GtmService,
GtmBuilderService,
GtmFbService,
BrandService,
ProductService,
StoreService,
HttpService,
LoaderService,
LocalStorage,
LocalStoragePolyfill,
MenusService,
RelationService,
CategoriesService,
OrdersService,
NewsletterService,
TermsService,
InsuranceService,
UrlService,
InstallmentService,
PriceService,
SuggestionsService,
SitemapsService,
CouponsService,
AddressesService,
ProfileService,
AccountOrdersService,
AuthGuardService,
WindowService,
MacroService,
BnaService,
PointsService,
PaginationService,
DespegarService,
TechnisysService,
AerolineasArgentinasService,
ProgramaVolarService,
SubeService,
RecargaCelularesService,
ReservableService,
CatalogService,
GeolocationService,
AerolineasCanActivateGuardService,
TrackingCanActivateGuardService,
{provide: MAT_DATE_LOCALE, useValue: 'es-AR'}
],
bootstrap: [AppComponent],
entryComponents: [
CartComponent,
ProviderFormComponent,
PhoneDialogComponent,
NewsletterComponent,
TijeComponent,
CheckoutDialogComponent,
LoginDialogComponent,
DialogEditProfileComponent,
DialogCreateAddressComponent,
DialogDeleteAddressComponent,
UpdateProfileDialogComponent,
TermsComponent,
TermsDialogComponent,
DecidirInsuranceComponent,
DecidirInsuranceReminderComponent,
PromotionDialogComponent,
PaymentMethodsComponent,
ExternalComponent,
DespegarComponent,
OfferComponent,
TermsOfServicesComponent,
AerolineasArgentinasComponent,
ProgramaVolarComponent,
InsuranceComponent,
SubeComponent,
NewCardComponent,
SelectCardComponent,
EditCardComponent,
HiddenSearchComponent,
// Landing components
Cols1Pics1V1Component,
Cols2GenericV1Component,
Cols4BrandsV1Component,
Cols4VariantsV1Component,
DefaultComponent,
HeroComponent,
EditorComponent,
HeroBannerComponent,
Cols1Pics2V1Component,
Cols2BrandV2Component,
Cols4LinksV1Component,
Cols4PostsV1Component,
Cols5GenericV1Component,
Cols2Pic3V1Component,
SliderComponent,
MiniLogoComponent,
SubscriptionComponent,
ReservationConfirmComponent,
GeolocationDialogComponent,
]
})
export class AppModule { }

