<!--TOP Bar-->
<!--*ngIf="env.name !== 'Macro' && env.name !== 'PaseoLibertad' && env.name !== 'BancoColumbia' && env.name !== 'BancoNacion'"-->
<div class="topbar" *ngIf="hasTopBar()">
  <div class="container">
    <div class="row">
      <!--TopBar for BancoNacion-->
      <!-- <ng-container *ngIf="env.name === 'BancoNacion' && (arrayMenus$)?.length">
        <div class="col-12 text-center d-flex mt-1 container-tags">
          <ng-container *ngFor="let menu of (arrayMenus$); let i = index" >
            <section class="sale tags-topbar" [ngStyle]="{backgroundImage: 'url(' + arrayTagsStyles[i].backgroundImage +')', zIndex: arrayTagsStyles[i].zIndex, left: arrayTagsStyles[i].left}">
              <img
                [src]="arrayTagsStyles[i].icon"
                alt="icon"
                [height]="arrayTagsStyles[i].height"
                [width]="arrayTagsStyles[i].width"
                (click)="navigate(menu.url, arrayTagsStyles[i].targetSelf)"
                style="margin-left: -25px;"
              >
              <span [ngStyle]="{'color': arrayTagsStyles[i].color}"></span>
            </section>
          </ng-container>

        </div>
      </ng-container> -->
      <!--End TopBar for BancoNacion-->

      <!-- Light top bar BancoNacion -->
      <!-- <div *ngIf="env.name === 'BancoNacion'" class="col-12 text-center">
        <p class="topbar-component bna-title-header">
          APROVECHA 18 CUOTAS FIJAS EN TODA LA TIENDA <span [innerHTML]="smileyFace"></span>
        </p>
      </div> -->
      <!-- End Light top bar BancoNacion-->

      <!-- Light top bar -->
      <div *ngIf="env.name !== 'BancoNacion'" class="col-12 text-center">
        <p id="topbarSales" class="sale topbar-component">
          <a [routerLink]="['/catalog']" [queryParams]="{d: 1}" >
            <span class="fe fe-tag mr-1"></span>Ofertas
          </a>
        </p>

        <p id="topbarTracking"class="topbar-component">
          <span class="fe fe-truck mr-1"></span>
          <a [routerLink]="['/tracking']">
            Segu&iacute; tu pedido
          </a>
        </p>

        <p id="topbarWhatsapp"class="topbar-component" *ngIf="env.whatsapp">
          <span class="fa fa-whatsapp mr-1"></span>
          <a href="https://wa.me/{{ env.whatsapp }}" target="_blank">
            Whatsapp
          </a>
        </p>

        <p id="topbarPhoneSales" class="topbar-component" *ngIf="env.phone" (click)="openPhone()">
          <span class="fe fe-phone-call mr-1"></span>
          Venta telefónica
        </p>

        <p id="topbarEmail" class="topbar-component" *ngIf="env.email">
          <span class="fe fe-help-circle mr-1"></span>
          <a *ngIf="env.name !== 'BancoCiudad' && env.name !== 'EspecialesBancoCiudad'" [routerLink]="['/preguntas-frecuentes']">Preguntas frecuentes</a>
          <a *ngIf="env.name === 'BancoCiudad' || env.name === 'EspecialesBancoCiudad'" href="https://gestiones.zendesk.com/hc/es" target="_blank"> Centro de ayuda</a>
        </p>

        <p id="topbarRegret" class="topbar-component">
          <span class="fe fe-rotate-ccw mr-1"></span>
          <a href="{{ env.regret_buy }}" target="blank">
            Botón de arrepentimiento
          </a>
        </p>

        <p id="topbarEmail" class="topbar-component">
          <span class="fe fe-alert-triangle mr-1"></span>
          <a *ngIf="env.name === 'BancoCiudad' || env.name === 'EspecialesBancoCiudad'" href="https://soporte.tiendaciudad.com.ar/hc/es/requests/new?ticket_form_id=28520688923411" target="_blank">Verificación de compra</a>
        </p>

      </div>
      <!-- End Light top bar -->

    </div>
  </div>
</div>
<!--end TOP Bar-->

<!--TopBar Mobile Banco Nacion-->
<!-- <ng-container *ngIf="env.name === 'BancoNacion' && (arrayMenus$)?.length">
  <div id="topbar-mobile-bna">
    <div class="topbar-mobile d-flex align-items-center shadow-sm">
      <div class="col-12 d-flex justify-content-center" style="background-color: #005f86;">
        <img [src]="arrayTagsStyles[0].icon" alt="icon" height="50" width="170" (click)="navigate(arrayMenus$[0].url, false)">
      </div> -->
      <!--      <div class="col-3 tab-viajes-mobile">-->
      <!--        <img src="/assets/stores/current_store/images/icons-tags-topbar/tag-viaje-mobile-2.svg" alt="icon" height="45" width="60" (click)="navigate(arrayMenus$[1].url)">-->
      <!--      </div>-->
    <!-- </div>
  </div>
</ng-container> -->
<!--end TopBar Mobile Banco Nacion-->

<!--Header-->
<div *ngIf="(isCheckout() && env.name == 'BancoNacion'); else normalFlow" class="head-bg-color">
</div>
<ng-template #normalFlow>
  <div class="head-bg-color" [ngClass]="{
  'mi-moto': env.name === STORE_NAME.MI_MOTO
  }">
    <div id="container">
      <div *ngIf="headerVersion === 0; then header_classic"></div>
      <div *ngIf="headerVersion === 1; then header_v1"></div>
      <div *ngIf="headerVersion === 2; then header_v2"></div>
    </div>
  </div>
</ng-template>
<!--End Header-->


<!--Header Tempates-->
<ng-template #header_classic>
  <div id="classic-header" class="mx-auto"  [ngClass]="{'py-2': env.name === 'BancoNacion'}">
    <div *ngIf="env.name !== 'MiMoto'" id="hamb-menu" (click)="openSide()">
      <i class="fe fe-menu fe-2x"></i>
    </div>

    <!-- LOGO DE LA TIENDA -->
    <div id="logo" [ngClass]="{'height-logo-bna d-flex justify-content-center': env.name === 'BancoNacion'}">
      <a (click)="goToHome()" [ngClass]="{'redirect': env.name === 'MiMoto'}">
        <img style="cursor: pointer;" *ngIf="env.name === 'BancoNacion'" src="/assets/stores/bna/images/store-header-logo.svg" alt="store logo" />
        <img *ngIf="env.name !== 'BancoNacion'" [src]="selecta ? '/assets/stores/current_store/images/store-logo-selecta-w.png' : '/assets/stores/current_store/images/store-logo.svg'" />
      </a>
    </div>
    <!-- LOGO DE LA TIENDA END -->

    <!-- SEARCH BAR -->
    <ng-container *ngIf="!isCheckout()">
      <div id="search-bar">
        <app-search-bar></app-search-bar>
      </div>
      <div *ngIf="env.name === 'BancoNacion' && !this.authenticationService.isLoggedIn()" class="d-none d-md-block ml-1">
        <button (click)="goToGetBnaCard()" class="header-buttons btn btn-primary btn-sm">Quiero mi tarjeta</button>
      </div>
      <div *ngIf="env.name == 'Macro' && !this.authenticationService.isLoggedIn()" class="d-none d-md-block ml-1">
        <button (click)="goToGetMacroCard()" class="header-buttons btn btn-primary btn-sm">Sacá tu tarjeta</button>
      </div>
      <div *ngIf="env.name == 'Macro'" class="d-none d-md-block ml-1">
        <button (click)="goToMacroAbout()" class="header-buttons btn btn-primary btn-sm">¿Cómo sumar puntos?</button>
      </div>
    </ng-container>
    <!-- SEARCH BAR END-->
    <div id="account-bar" class="ml-2 d-none d-md-block" [ngClass]="isCheckout() ? 'right' : ''">
      <app-account-nav *ngIf="env.login" [isMobile]="false"></app-account-nav>
    </div>
    <ng-container *ngIf="!isCheckout() && env.cart">
      <div [attr.data-tracking-id]="env.name === 'BancoNacion' ? 'Boton-Carrito' : null" id="cart" (click)="openCart()" *ngIf="env.gateway !== 'sportclub'">
        <span class="quant" *ngIf="quantity > 0">{{ quantity }}</span>
        <i [ngClass]="env.name === 'PaseoLibertad' ? 'fe fe-2x fe-shopping-bag' : 'fe fe-2x fe-shopping-cart'" aria-hidden="true" [ngStyle]="{'color': env.name === 'BancoNacion' ? '#005f86' : 'unset'}"></i>
      </div>
    </ng-container>

    <!-- BOTON ARREPENTIMIENTO -->
    <ng-container *ngIf="!isCheckout()">
      <div id="menuRegret" class="btn btn-outline-secondary">
        <a href="{{ env.name === 'BancoCiudad' ? 'https://hacetecliente.bancociudad.com.ar/#/paquete' : env.regret_buy }}" target="blank">
          {{env.name === 'BancoCiudad' ? 'Solicitá tu tarjeta' : 'Botón de arrepentimiento'}}
        </a>
      </div>
    </ng-container>
    <!-- BOTON ARREPENTIMIENTO END-->
  </div>
</ng-template>

<!--Header V1-->
<ng-template #header_v1>
  <div id="header-v1">
    <div id="hamb-menu" (click)="openSide()">
      <i class="fe fe-menu fe-2x"></i>
    </div>
    <div id="logo">
      <a routerLink="/">
        <img *ngIf="env.name === 'BancoNacion'" src="/assets/stores/bna/images/store-header-logo.svg" alt="store logo" />
        <img *ngIf="env.name !== 'BancoNacion'" src="/assets/stores/current_store/images/store-logo.svg" alt="store logo" />
      </a>
    </div>
    <div id="mobile-cart" (click)="openCart()" *ngIf="env.cart">
      <span class="quant" *ngIf="quantity > 0">{{ quantity }}</span>
      <i class="{{env.name === 'PaseoLibertad' ? 'fe fe-2x fe-shopping-bag' : 'fe fe-2x fe-shopping-cart'}}"></i>
    </div>
    <div id="user-bar" *ngIf="!isCheckout()" >
      <div id="menuRegret" class="col-12 text-center">
        <a href="{{ env.regret_buy }}" class="btn btn-outline-secondary" target="blank">Botón de arrepentimiento</a>
      </div>
      <div id="menuUser" class="col-12">
        <div id="search-bar">
          <div id="input-search-bar"><app-search-bar></app-search-bar></div>
          <div id="hidden-search" (click)="openHiddenSearch()">
            <i class="fe fe-search"  #hiddenSearch></i>
          </div>
        </div>
        <div id="account-bar">
          <app-account-nav *ngIf="env.login" [isMobile]="false"></app-account-nav>
        </div>
        <div id="cart" (click)="openCart()" *ngIf="env.cart || env.gateway !== 'sportclub'">
          <span class="quant" *ngIf="quantity > 0">{{ quantity }}</span>
          <i class="{{env.name === 'PaseoLibertad' ? 'fe fe-2x fe-shopping-bag' : 'fe fe-2x fe-shopping-cart'}}"></i>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!--Header V2-->
<ng-template #header_v2>
  <div id="header-v2">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light border-bottom p-md-0 px-2">
        <a class="navbar-brand" routerLink="/">
          <img src="/assets/stores/current_store/images/store-logo.svg" alt="store logo" />
        </a>
        <ul class="navbar-nav d-lg-none ml-auto navbar-icons d-flex flex-row">
          <div id="account-bar" class="pointer" [ngClass]="isCheckout() ? 'right' : ''">
            <app-account-nav *ngIf="env.login" [isMobile]="false"></app-account-nav>
          </div>
          <div id="cart" (click)="openCart()" *ngIf="env.cart || env.gateway !== 'sportclub'" class="mr-3 ml-2">
            <span class="quant" *ngIf="quantity > 0">{{ quantity }}</span>
            <i class='fe fe-2x fe-shopping-cart' aria-hidden="true"></i>
          </div>
        </ul>

        <button class="navbar-toggler p-0 border-0 d-md-none" type="button" data-toggle="offcanvas" (click)="toggleMenu()">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div id="search-bar" class="w-100">
          <app-search-bar></app-search-bar>
        </div>


        <div id="account-bar" class="mx-3 d-none d-lg-block pointer" [ngClass]="isCheckout() ? 'right' : ''">
          <app-account-nav *ngIf="env.login" [isMobile]="false"></app-account-nav>
        </div>

        <div id="cart" (click)="openCart()" *ngIf="env.cart || env.gateway !== 'sportclub'" class="d-none d-lg-block">
          <span class="quant" *ngIf="quantity > 0">{{ quantity }}</span>
          <i class='fe fe-2x fe-shopping-cart' aria-hidden="true"></i>
        </div>


        <div class="navbar-collapse offcanvas-collapse" id="header-v2-collapse">

            <div class="d-md-none" role="tablist" aria-multiselectable="true">
              <div class="d-flex p-3">
                <i class='fe fe-2x fe-x ml-auto' aria-hidden="true" (click)="toggleMenu()"></i>
              </div>

              <div class="px-3 pb-3">
                <app-account-nav *ngIf="env.login" [isMobile]="true" (click)="toggleMenu()" class="pl-3"></app-account-nav>
              </div>

              <!-- from component  -->
              <app-button-link-mobile
                [menu]="menu"
                [position]="i"
                *ngFor="let menu of menus; let i = index"
                (closed)="toggleMenu()">
              </app-button-link-mobile>
              <!-- end from component  -->

            </div>

        </div>

      </nav>
    </div>
  </div>


</ng-template>

<ng-container *ngIf="!isCheckout()">
  <div id="categories" #categories>
    <!-- FOR DESKTOPS -->
    <ul id="desktop-menu">
      <!-- TRAE DEL ENDPOINT UN TEXT CON PROMO ESTUDIANTES -->
      <li class="categories-menu" *ngFor="let menu of menus">
        <app-button-link
          [menu]="menu"
          [ngClass]="setClass(env.name, menu)"
          (mouseenter)="showMenu($event)"
          (mouseleave)="hideMenu($event)"
          (click)="hideMenu($event)">
        </app-button-link>
      </li>
    </ul>
    <!-- FOR MOBILE -->
    <div id="mobile-menu" #categories>
      <div id="accordion" role="tablist" aria-multiselectable="true">

        <!-- from component  -->
        <app-button-link-mobile
          [menu]="menu"
          [position]="i"
          *ngFor="let menu of menus; let i = index"
          (closed)="closeSide()">
        </app-button-link-mobile>
        <app-account-nav *ngIf="env.login" [isMobile]="true" (click)="closeSide()" class="pl-3"></app-account-nav>
        <!-- end from component  -->

      </div>
    </div>

  </div>

</ng-container>

<ng-container *ngIf="geolocation">
  <div class="row mx-0 px-2 mx-md-0" id="geolocation-bar">
    <div class="container">
      <nav class="nav nav-underline">
        <a class="nav-link pl-0 pr-1 pr-md-2 pointer" (click)="geolocate()"><i class="fe fe-map-pin mr-2 align-bottom"></i>Mi Ubicación</a>
        <span class="nav-link pl-0 pr-1 pr-md-2">|</span>
        <a class="nav-link pl-0 pr-1 pr-md-2 pointer" *ngIf="!is_located" (click)="geolocate()">Tu ubicación nos ayudará a darte una mejor experiencia de compra.</a>
        <a class="nav-link pl-0 pr-1 pr-md-2 pointer" *ngIf="is_located" (click)="geolocate()">{{location.city}} - {{location.province}}</a>
      </nav>
    </div>
  </div>
</ng-container>

<div id="overlay" (click)="closeSide()"></div>
